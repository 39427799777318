html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.swal2-container {
  z-index: 9999 !important;
}

.swal2-title {
  line-height: 30px !important;
}

::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0) !important;
  width: 6px;
  height: 6px;
  z-index: 999999;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 16px;
  border: 0px solid inherit;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

/* scrollbar when element is hovered */
:hover::-webkit-scrollbar-thumb {
  background-color: #a0a0a5;
  border: 4px solid inherit;
}

/* scrollbar when scrollbar is hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid inherit;
}

/* calendar separator fix style */
.MuiDateTimePickerToolbar-separator {
  display: flex;
  align-items: center;
}

.closing-animation {
  animation: scaleAndFly 2s forwards;
}

@keyframes scaleAndFly {
  0% {
    transform: scale(1) translate(0, 0);
  }
  20% {
    transform: scale(0.4) translate(-250px, 0);
  }
  70% {
    transform: scale(0.6) translate(1500px, 0);
  }
  100% {
    transform: scale(0.6) translate(1500px, 0);
  }
}

.select-color-container > div {
  height: 35px !important;
  width: 35px !important;
}
